<template>
  <div id="satisfactionReport">
    <el-dialog
      :title="satisfactionReportFormTitle"
      width="500px"
      :visible.sync="satisfactionReportDialogVisible"
      :close-on-click-modal="false"
      @close="satisfactionReportDialogClose"
    >
      <el-form
        ref="satisfactionReportFormRef"
        :model="satisfactionReportForm"
        :rules="satisfactionReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="编号" prop="reportNo">
          <el-input v-model="satisfactionReportForm.reportNo" placeholder="请输入编号" clearable />
        </el-form-item>
        <el-form-item label="报告日期" prop="reportDate">
          <el-date-picker v-model="satisfactionReportForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="报告内容" prop="reportContent">
          <el-input
            v-model="satisfactionReportForm.reportContent"
            placeholder="请输入报告内容"
            type="textarea"
            :rows="4"
          />
        </el-form-item>
        <el-form-item label="总结" prop="summary">
          <el-input
            v-model="satisfactionReportForm.summary"
            placeholder="请输入总结"
            type="textarea"
            :rows="4"
          />
        </el-form-item>
        <el-form-item label="建议的纠正和预防措施" prop="measures">
          <el-input
            v-model="satisfactionReportForm.measures"
            placeholder="请输入建议的纠正和预防措施"
            type="textarea"
            :rows="4"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="satisfactionReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="satisfactionReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.reportNo" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="satisfactionReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="reportNo" label="编号" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reportContent" label="报告内容" />
      <el-table-column prop="summary" label="总结" />
      <el-table-column prop="measures" label="建议的纠正和预防措施" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reportTime">{{ scope.row.reportTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="satisfactionReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSatisfactionReport,
  deleteSatisfactionReport,
  updateSatisfactionReport,
  selectSatisfactionReportInfo,
  selectSatisfactionReportList
} from '@/api/sale/satisfactionReport'
import moment from 'moment'

export default {
  data () {
    return {
      satisfactionReportDialogVisible: false,
      satisfactionReportFormTitle: '',
      satisfactionReportForm: {
        id: '',
        reportNo: '',
        reportDate: '',
        reportContent: '',
        summary: '',
        measures: ''
      },
      satisfactionReportFormRules: {
        reportNo: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      satisfactionReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        reportNo: ''
      }
    }
  },
  created () {
    selectSatisfactionReportList(this.searchForm).then(res => {
      this.satisfactionReportPage = res
    })
  },
  methods: {
    satisfactionReportDialogClose () {
      this.$refs.satisfactionReportFormRef.resetFields()
    },
    satisfactionReportFormSubmit () {
      if (this.satisfactionReportFormTitle === '顾客满意度报告详情') {
        this.satisfactionReportDialogVisible = false
        return
      }
      this.$refs.satisfactionReportFormRef.validate(async valid => {
        if (valid) {
          if (this.satisfactionReportFormTitle === '新增顾客满意度报告') {
            this.satisfactionReportForm.id = ''
            await addSatisfactionReport(this.satisfactionReportForm)
          } else if (this.satisfactionReportFormTitle === '修改顾客满意度报告') {
            await updateSatisfactionReport(this.satisfactionReportForm)
          }
          this.satisfactionReportPage = await selectSatisfactionReportList(this.searchForm)
          this.satisfactionReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.satisfactionReportFormTitle = '新增顾客满意度报告'
      this.satisfactionReportDialogVisible = true
      this.satisfactionReportForm.reportNo = 'GK' + moment().format('YYYY') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSatisfactionReport(row.id)
        if (this.satisfactionReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.satisfactionReportPage = await selectSatisfactionReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.satisfactionReportFormTitle = '修改顾客满意度报告'
      this.satisfactionReportDialogVisible = true
      this.selectSatisfactionReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.satisfactionReportFormTitle = '顾客满意度报告详情'
      this.satisfactionReportDialogVisible = true
      this.selectSatisfactionReportInfoById(row.id)
    },
    selectSatisfactionReportInfoById (id) {
      selectSatisfactionReportInfo(id).then(res => {
        this.satisfactionReportForm.id = res.id
        this.satisfactionReportForm.reportNo = res.reportNo
        this.satisfactionReportForm.reportDate = res.reportDate
        this.satisfactionReportForm.reportContent = res.reportContent
        this.satisfactionReportForm.summary = res.summary
        this.satisfactionReportForm.measures = res.measures
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSatisfactionReportList(this.searchForm).then(res => {
        this.satisfactionReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSatisfactionReportList(this.searchForm).then(res => {
        this.satisfactionReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSatisfactionReportList(this.searchForm).then(res => {
        this.satisfactionReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
