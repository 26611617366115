import axios from '@/common/axios'
import qs from 'qs'

export function addSatisfactionReport (data) {
  return axios({
    method: 'post',
    url: '/sale/report/add',
    data: qs.stringify(data)
  })
}

export function deleteSatisfactionReport (id) {
  return axios({
    method: 'delete',
    url: '/sale/report/delete/' + id
  })
}

export function updateSatisfactionReport (data) {
  return axios({
    method: 'put',
    url: '/sale/report/update',
    data: qs.stringify(data)
  })
}

export function selectSatisfactionReportInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/report/info/' + id
  })
}

export function selectSatisfactionReportList (query) {
  return axios({
    method: 'get',
    url: '/sale/report/list',
    params: query
  })
}
